import { ContentType, HttpClient, RequestParams } from '../http-client';

export class CompanySetting<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getCompanySettingCrewRank = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/setting/crew-rank`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanySettingCrewRankDepartment = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/setting/crew-rank/department`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  updateCompanySettingCrewRank = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/setting/crew-rank`,
      method: 'POST',
      secure: true,
      type: ContentType.Json,
      body: data,
      format: 'json',
      ...params,
    });

  deleteCompanySettingCrewRankById = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/setting/crew-rank/${data.id}`,
      method: 'DELETE',
      secure: true,
      type: ContentType.Json,
      body: data,
      format: 'json',
      ...params,
    });

  getCompanySettingDefaultCrewStatus = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/autofill/settings/status`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanySettingCrewStatus = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/setting/crew-status`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  updateCompanySettingCrewStatus = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/setting/crew-status`,
      method: 'POST',
      secure: true,
      type: ContentType.Json,
      body: data,
      format: 'json',
      ...params,
    });

  deleteCompanySettingCrewStatus = (id: number, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/setting/crew-status/${id}`,
      method: 'DELETE',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanySettingCrewSchedule = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/setting/crew-schedule`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  updateCompanySettingCrewSchedule = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/setting/crew-schedule`,
      method: 'POST',
      secure: true,
      type: ContentType.Json,
      body: data,
      format: 'json',
      ...params,
    });

  getCompanyUserNotification = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/user/notification-setting`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  updateCompanyUserNotification = (
    data: any,
    params: RequestParams = {}
  ) =>
    this.http.request<any>({
      path: `/api/v2/company/user/notification-setting/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanyUserInboxReset = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/user/inboxes/reset`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanyUserInbox = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/user/inboxes`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  updateCompanyUserInboxById = (id: number, payload: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/user/inboxes/${id}`,
      method: 'PATCH',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      body: payload,
      ...params,
    });

  getCompanyUserBadges = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/user/badges`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanyUserInboxesRead = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/user/inboxes/read`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
