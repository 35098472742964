import Head from 'next/head';
import { HeadProps } from './types';

function HeadMetaTag({
  title: titleProp,
  keywords,
  author,
  description,
  titleOg,
  descriptionOg,
  imageUrl,
  robots,
  googleBot,
}: HeadProps) {
  const title = titleProp || 'OnBoard Crew App | Portal Karir Untuk Pelaut';
  const facebookTitle = titleOg || title;
  const facebookDescription = descriptionOg || description || 'Raih Karir Pelaut Impianmu!';
  const twitterDescription = facebookDescription;
  const imageMeta = imageUrl || 'https://directus.onboardcrewapp.com/assets/eaf53392-2f9a-49cc-8434-6c7ca1381b85';

  return (
    <Head>
      <title>{title}</title>

      <meta name='title' content={title} />
      {author && <meta name='author' content={author} />}
      {keywords && <meta name='keywords' content={keywords} />}
      <meta name='description' content={facebookDescription} />

      <meta property='og:title' content={facebookTitle} />
      <meta property='og:image' content={imageMeta} />
      <meta property='og:description' content={facebookDescription} />
      <meta property='og:site_name' content='OnBoard Crew App' />
      <meta property="og:type" content='website' />

      <meta name='twitter:description' content={twitterDescription} />
      <meta name='twitter:title' content={facebookTitle} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:image' content={imageMeta} />

      {robots && <meta name='robots' content={robots} />}
      {googleBot && <meta name='googlebot' content={googleBot} />}
    </Head>
  );
}

export default HeadMetaTag;
